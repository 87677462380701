import React from 'react';
import { Box, H1, ListPage } from '@procore/core-react';

export function ListPageLayout(
  props: React.PropsWithChildren<{ title: string }>
) {
  return (
    <ListPage>
      <ListPage.Main>
        <Box marginBottom="md">
          <H1>
            {props.title}
            <ListPage.Body>{props.children}</ListPage.Body>
          </H1>
        </Box>
      </ListPage.Main>
    </ListPage>
  );
}
