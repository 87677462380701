import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { Home } from './pages/home';
import { AddTodo } from './pages/todos/Add';
import { Todos } from './pages/todos';
import { Todo } from './pages/todos/[id]';
import './Application.css';

const client = new QueryClient();

export function Application() {
  return (
    <QueryClientProvider client={client}>
      <Router>
        <React.Suspense fallback="loading...">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/todos">
              <Route index element={<Todos />} />
              <Route path="add" element={<AddTodo />} />
              <Route path=":id" element={<Todo />} />
            </Route>
          </Routes>
        </React.Suspense>
      </Router>
    </QueryClientProvider>
  );
}
