import React from 'react';
import { DetailPage, Title, H1 } from '@procore/core-react';

export interface DetailPageProps {
  title: string;
}

export function DetailPageLayout(
  props: React.PropsWithChildren<DetailPageProps>
) {
  return (
    <DetailPage>
      <DetailPage.Main>
        <DetailPage.Header>
          <DetailPage.Title>
            <Title>
              <Title.Text>
                <H1>{props.title}</H1>
              </Title.Text>
            </Title>
          </DetailPage.Title>
        </DetailPage.Header>
        <DetailPage.Body>
          <DetailPage.Card>
            <DetailPage.Section>{props.children}</DetailPage.Section>
          </DetailPage.Card>
        </DetailPage.Body>
      </DetailPage.Main>
    </DetailPage>
  );
}
