import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(process.env.PROCORE_HYDRA_SCHEMA_ENDPOINT as string, {
    method: "POST",
    ...({"headers":{"Content-Type":"application/json"}}),
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Mutation = {
  __typename?: 'Mutation';
  addTodo?: Maybe<TodoItem>;
  deleteTodo?: Maybe<Scalars['Boolean']>;
};


export type MutationAddTodoArgs = {
  expiration?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};


export type MutationDeleteTodoArgs = {
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  todo?: Maybe<TodoItem>;
  todos?: Maybe<TodoList>;
};


export type QueryTodoArgs = {
  name: Scalars['ID'];
};

export type TodoItem = {
  __typename?: 'TodoItem';
  expiration?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TodoList = {
  __typename?: 'TodoList';
  cursor?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<TodoItem>>>;
};

export type AddTodoMutationVariables = Exact<{
  value: Scalars['String'];
  expiration?: InputMaybe<Scalars['String']>;
}>;


export type AddTodoMutation = { __typename?: 'Mutation', addTodo?: { __typename?: 'TodoItem', name?: string | null, value?: string | null, expiration?: string | null } | null };

export type DeleteTodoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTodoMutation = { __typename?: 'Mutation', deleteTodo?: boolean | null };

export type GetTodoQueryVariables = Exact<{
  name: Scalars['ID'];
}>;


export type GetTodoQuery = { __typename?: 'Query', todo?: { __typename?: 'TodoItem', name?: string | null, expiration?: string | null, value?: string | null } | null };

export type GetTodosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTodosQuery = { __typename?: 'Query', todos?: { __typename?: 'TodoList', results?: Array<{ __typename?: 'TodoItem', expiration?: string | null, name?: string | null, value?: string | null } | null> | null } | null };


export const AddTodoDocument = `
    mutation addTodo($value: String!, $expiration: String) {
  addTodo(value: $value, expiration: $expiration) {
    name
    value
    expiration
  }
}
    `;
export const useAddTodoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddTodoMutation, TError, AddTodoMutationVariables, TContext>) =>
    useMutation<AddTodoMutation, TError, AddTodoMutationVariables, TContext>(
      ['addTodo'],
      (variables?: AddTodoMutationVariables) => fetcher<AddTodoMutation, AddTodoMutationVariables>(AddTodoDocument, variables)(),
      options
    );
export const DeleteTodoDocument = `
    mutation deleteTodo($id: ID!) {
  deleteTodo(id: $id)
}
    `;
export const useDeleteTodoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteTodoMutation, TError, DeleteTodoMutationVariables, TContext>) =>
    useMutation<DeleteTodoMutation, TError, DeleteTodoMutationVariables, TContext>(
      ['deleteTodo'],
      (variables?: DeleteTodoMutationVariables) => fetcher<DeleteTodoMutation, DeleteTodoMutationVariables>(DeleteTodoDocument, variables)(),
      options
    );
export const GetTodoDocument = `
    query getTodo($name: ID!) {
  todo(name: $name) {
    name
    expiration
    value
  }
}
    `;
export const useGetTodoQuery = <
      TData = GetTodoQuery,
      TError = unknown
    >(
      variables: GetTodoQueryVariables,
      options?: UseQueryOptions<GetTodoQuery, TError, TData>
    ) =>
    useQuery<GetTodoQuery, TError, TData>(
      ['getTodo', variables],
      fetcher<GetTodoQuery, GetTodoQueryVariables>(GetTodoDocument, variables),
      options
    );
export const GetTodosDocument = `
    query getTodos {
  todos {
    results {
      expiration
      name
      value
    }
  }
}
    `;
export const useGetTodosQuery = <
      TData = GetTodosQuery,
      TError = unknown
    >(
      variables?: GetTodosQueryVariables,
      options?: UseQueryOptions<GetTodosQuery, TError, TData>
    ) =>
    useQuery<GetTodosQuery, TError, TData>(
      variables === undefined ? ['getTodos'] : ['getTodos', variables],
      fetcher<GetTodosQuery, GetTodosQueryVariables>(GetTodosDocument, variables),
      options
    );