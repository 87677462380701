import React from 'react';
import { useParams } from 'react-router-dom';

import { Form } from '@procore/soa-ui';
import { DetailPageLayout } from '../../../layouts';
import { useGetTodoQuery } from '../../../generated/graphql';

const rootSchema = require('../../../../../worker/gen/json-schema/typescript-json-schema.json');
const schema = rootSchema.definitions.TodoItem;

// don't render __typename/expiration
const uiSchema = {
  __typename: {
    'ui:options': {
      widget: () => null,
    },
  },
  // expiration is only returned when listing keys
  expiration: {
    'ui:options': {
      widget: () => null,
    },
  },
};

export function Todo() {
  const params = useParams();
  const { id } = params;
  const { data } = useGetTodoQuery({
    name: id as string,
  });
  const todo = data?.todo;
  const title = todo?.value || 'Todo';

  return (
    <DetailPageLayout title={title}>
      <Form schema={schema} uiSchema={uiSchema} formData={todo} view="read" />
    </DetailPageLayout>
  );
}
