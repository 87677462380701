import React from 'react';
import { Box, Button, Flex } from '@procore/core-react';
// eslint-disable-next-line
import { Plus } from '@procore/core-icons/dist';
import { Link, useNavigate } from 'react-router-dom';
import { JsonSchemaDataTable } from '@procore/soa-ui';
import { useQueryClient } from '@tanstack/react-query';

import {
  useGetTodosQuery,
  useDeleteTodoMutation,
} from '../../generated/graphql';
import { ListPageLayout } from '../../layouts';

const rootSchema = require('../../../../worker/gen/json-schema/typescript-json-schema.json');
const schema = rootSchema.definitions.TodoList;

function isEditable() {
  return false;
}

export function Todos() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data, refetch } = useGetTodosQuery();
  const { mutateAsync } = useDeleteTodoMutation();

  queryClient.invalidateQueries({ queryKey: ['todos'] });

  function onDelete(id: string | number) {
    mutateAsync({ id: id as string }).then(() => {
      refetch();
    });
  }

  return (
    <ListPageLayout title="Todos">
      <Box style={{ height: '800px', marginBottom: '10px' }}>
        <JsonSchemaDataTable
          schema={schema}
          data={data?.todos || { results: [] }}
          idField="name"
          isEditable={isEditable}
          navigate={navigate}
          onDelete={onDelete}
          collectionName="todos"
        />
      </Box>
      <Flex direction="row">
        <Flex flex="1" />
        <Flex flex="0">
          <Button
            icon={<Plus />}
            onClick={() => {
              refetch();
            }}
            style={{ marginRight: '5px' }}
          >
            Refresh
          </Button>
        </Flex>
        <Flex flex="0">
          <Link to="/todos/add">
            <Button icon={<Plus />}>Add</Button>
          </Link>
        </Flex>
      </Flex>
    </ListPageLayout>
  );
}
