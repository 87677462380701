import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Application } from './Application';

let root: Root;
function render() {
  if (!root) {
    root = createRoot(document.getElementById('root') as Element);
  }
  root.render(<Application />);
}

render();

if (module.hot) {
  module.hot.accept('./Application', render);
}
