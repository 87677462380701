import React from 'react';
import { Link } from 'react-router-dom';

export function Home() {
  return (
    <div style={{ textAlign: 'center', textTransform: 'uppercase' }}>
      <Link to="/todos">
        <h1>Todos</h1>
      </Link>
    </div>
  );
}
